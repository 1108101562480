<template>
    <OModal 
        name="auditTrailDialog" 
        ref="auditTrailDialog"
    > 
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("Audit trail") }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0" style="height:700px; overflow:none;">
                    <iframe v-if="isMounted" ref="audit_trail_frame" id="audittrailFrame" :src="`/nt/datachangelog?ParentTable=${props.audit_table}&ID=${props.audit_id}&HideNav=true`" style="border:0;width:100%;height:100%"></iframe>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('Close') }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup lang="ts">
    import { ref, onMounted, onUnmounted } from 'vue';
    import { alert } from 'o365-vue-services';
    import { $t } from 'o365-utils';

    const auditTrailDialog = ref(null);
    const audit_trail_frame = ref(null);
    const isMounted = ref(false);

     const props = defineProps({
        "audit_table": {
            type: String
        },
        "audit_id": {
            type: Number
        }
    });

    onMounted(() => {
        props.audit_table === null || props.audit_id === null ? 
            alert($t('Unable to load audit trail.')) : isMounted.value = true;
    });

    onUnmounted(() => {
        isMounted.value = false;
    });
</script>